import React, { useEffect, useState } from 'react';
import { Button, Header, Message, Stat, Table } from 'react-frontier';
import { addCommas } from '../../Util';

interface InstructionsProps{
	download_url?: string,
	amount: number,
	transfer_data?: {
		bank: string,
		name: string,
		clabe: string,
		concept: string,
		agreement: string,
	},
}
var InstructionsSPEI = (props: InstructionsProps)=>{
	return <div>
		<Header text='Transferencia SPEI' />
		<Stat value={addCommas(props.amount)} label='Total por pagar' style={{ paddingBottom: 0 }} />
		{/* {!!props.download_url && (
			<Button size='big' target='_blank' href={props.download_url} style={{ width: 280, margin: 'auto', display: 'block', marginTop: 10, marginBottom: 15 }} text='Descargar instrucciones' iconName='pdf file' />
		)} */}
		{!!props.transfer_data && <>
			<Header size='small' text='Desde BBVA' subtext='Dentro del menú de "Pagar" seleccione la opción "De Servicios" e ingrese el siguiente' />
			<Table
				details
				striped
				title='Datos de transferencia'
				className='unfitted'
				style={{ marginTop: 15, marginBottom: 15 }}
				data={[
					['Convenio CIE', props.transfer_data.agreement],
					['Referencia', props.transfer_data.name],
					['Concepto', `${props.transfer_data.concept}`],
				]}
			/>

			<Header size='small' text='Desde cualquier otro banco' subtext='Ingresa a la sección de transferencias y pagos o pagos a otros bancos y proporciona los datos de la transferencia.' />
			<Table
				details
				striped
				title='Datos de transferencia'
				className='unfitted'
				style={{ marginTop: 15 }}
				data={[
					['Beneficiario', 'AREMA Ticket'],
					['Banco destino', props.transfer_data.bank],
					['CLABE', props.transfer_data.clabe],
					['Concepto', `${props.transfer_data.concept}`],
					['Referencia', props.transfer_data.name],
				]}
			/>
		</>}

		<Message type='info' style={{ marginTop: 15, marginBottom: 10 }}>
			<div className="header centered">¡Atención!</div>
			<ul>
				<li>AREMA Ticket no se hace responsable si se deposita el importe a una cuenta diferente a la indicada. Favor de asegurarse de que se deposite a la cuenta indicada.</li>
				<li>El proceso despues de realizar la transferencia correctamente puede tardarse <b>30-60 minutos</b>.</li>
				<li>Antes de contactarnos, favor de asegurarse de que se le haya hecho el cargo a su cuenta bancaria despues de realizar la transferencia.</li>
			</ul>
		</Message>
		<Message type='orange'>
			Al completar estos pasos, <b>AREMA Ticket</b> te enviará un correo con tus boletos.
		</Message>
	</div>
}

export default InstructionsSPEI;