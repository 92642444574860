import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { CDN_ROOT, PAGE_PUBLIC } from './CheckoutConfig';
import { Outlet, RouterProvider, createBrowserRouter, useRouteError } from 'react-router-dom';
import { Header, SitePrivacy } from '@arema/components';

import 'fomantic-ui-css/semantic.css';
import 'frontier-css/css/frontier.css';
import './styles/checkout.scss'

import {
	Checkout, 
	Payment
} from './screens';
import { TitleProvider } from '@arema/components/Hooks';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

var ErrorElement = ()=>{
	const error = (useRouteError() as any);
	return <div>
		<div className="fr centered header" style={{ fontSize: 100 }}>
			{error.status}
			<div className="sub header" style={{ fontSize: 20 }}>
				{error.status===404 ? 'Página no encontrada' : (
					`Hubo un error inesperado. (${error.status})`
				)}
			</div>
		</div>
	</div>
}

var PublicRedirect = ()=>{
	useEffect(()=>{
		if(!process.env.REACT_APP_DEBUG){
			window.location.href = process.env.REACT_APP_PAGE_PUBLIC;
		}
	}, []);

	return <Header text='Redirect DEV' />;
}

var SiteContainer = (props: { outlet: JSX.Element })=>{
	return <div style={{ paddingBottom: 50 }}>
		<TitleProvider suffix='AREMA Ticket'>
			{props.outlet}
		</TitleProvider>
	</div>
}

const Router = createBrowserRouter([{
	path: '/',
	errorElement: <ErrorElement />,
	element: <SiteContainer outlet={<Outlet />} />,
	children: [
		{ path: '/', element: <PublicRedirect /> },
		{ path: '/privacidad', element: <SitePrivacy /> },
		{ path: '/:date', element: <Checkout /> }, 
		{ path: '/c/:token', element: <Payment /> },
		{ path: '/c/:token/pago', element: <Payment payment /> },
	]
}]);


var Root = ()=>{
	return <div>
		<div className="ar header">
			<a href={PAGE_PUBLIC}>
				<img src={`${CDN_ROOT}/assets/logo/LogoWH_Large.png`} alt="AREMA" />
			</a>
		</div>
		<div style={{ marginTop: 20 }}>
			<RouterProvider router={Router} />
		</div>
	</div>
}

root.render(<Root />);