import React, { useEffect, useState } from 'react';

var SitePrivacy = ()=>{
	return <div style={{ margin: 'auto', maxWidth: 700, marginTop: 20 }}>
		<div style={{ textAlign: 'center' }}>
			<h1 style={{ marginBottom: 0 }}>Aviso de Privacidad de AREMA Ticket®</h1>
			<h2 style={{ fontSize: 16, marginTop: 5 }}>Estimado Cliente o Usuario:</h2>
		</div>
		<p style={{ textAlign: 'center' }}>
			Conforme a lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los
			Particulares (La presente Ley es de orden público y de observancia general en toda la
			República y tiene por objeto la protección de los datos personales en posesión de los
			particulares, con la finalidad de regular su tratamiento legítimo, controlado e informado, a
			efecto de garantizar la privacidad y el derecho a la autodeterminación informativa de las
			personas) y en cumplimiento de la obligación a cargo de AREMA Ticket® (a través de la persona
			moral responsable de los datos personales) consistente en proteger los datos personales de sus
			clientes, afiliados y/o beneficiados de cualquiera de los productos y/o servicios pertenecientes al
			concepto comercialmente conocido como AREMA Producciones y Servicios, hacemos de tu
			conocimiento lo siguiente:
		</p>

		<h1>1. Contenido de los Datos Personales</h1>
		<p>
			De acuerdo con la Ley, se entiende como “Datos Personales” cualquier información concerniente a
			una persona física identificada o identificable. Para efectos del presente Aviso de Privacidad, de
			manera enunciativa mas no limitativa, se entenderán como “Datos Personales”:
		</p>
		<h2>1.1. Datos generales</h2>
		<p>
			Nombre(s) y apellidos, fecha de nacimiento, sexo, estado civil, existencia o no de hijos, domicilio,
			teléfono fijo (de casa u oficina), teléfono celular, correo electrónico, entre otros datos similares
			concernientes a una persona.
		</p>
		<h2>1.2. Datos relacionados con solicitudes de empleo</h2>
		<p>
			Cuando proporciones Datos Personales (en el correo ubicado en la sección “únete” del Sitio de
			AREMA en la Internet) en la bolsa de trabajo de AREMA Ticket®, además de los datos generales,
			se considerarán Datos Personales la información sobre nivel educativo, experiencia laboral y
			cualquier información relacionada con el currículum vitae que nos envíes.
		</p>
		<h2>1.3. Boletines y promociones en línea</h2>
		<p>
			Cuando te suscribas para recibir alguno de los productos de AREMA Ticket® (tales como News
			Letter, promociones especiales, boletines electrónicos, entre otros) y cuando realices transacciones
			para la compra de boletos en cualquiera de nuestras plataformas digitales, además de los datos
			generales, se considerarán Datos Personales Sensibles los relacionados con tarjetas bancarias,
			tales como nombre del tarjetahabiente, número de tarjeta, fecha de vencimiento de la tarjeta, tipo
			de tarjeta y código de seguridad, además del domicilio de entrega del producto y cualquier otra
			información necesaria para la suscripción o transacción en línea. Los Datos Personales referidos
			en este punto únicamente estarán sujetos a las finalidades del tratamiento establecidas más
			adelante en el presente Aviso de Privacidad, por el tiempo que dure la transacción comercial; es
			decir, una vez que la transacción y sus efectos concluyan, los Datos Personales serán eliminados
			de nuestras bases de datos.
		</p>
		<h2>1.4. Redes sociales</h2>
		<p>
			Las redes sociales (tales como Facebook® y Twitter®, entre otras) constituyen una plataforma de
			comunicación y de interconexión entre plataformas digitales de los distintos usuarios, son ajenas a
			AREMA Ticket® y, por lo tanto, no se encuentran bajo su responsabilidad.
		</p>
		<p>
			La información que proporciones dentro de redes sociales en las que AREMA Ticket® participa
			como usuario, no constituye ni forma parte de los Datos Personales sujetos a la protección de este
			Aviso de Privacidad, siendo responsabilidad de la empresa prestadora de esa plataforma y de
			quien lo publica.
		</p>
		<p>
			Los Datos Personales mencionados en los apartados 1.1 al 1.3 del presente Aviso de Privacidad
			serán recabados mediante el llenado de los formatos y/o mediante la recopilación de información o
			documentación requerida por AREMA Ticket®, ya sea de manera personal, por vía telefónica o vía
			electrónica en el sitio de AREMA Ticket® presentes (www.arema.mx).
		</p>
		
		<h1>2. Persona responsable de recabar Datos Personales</h1>
		<p>
			La persona moral responsable de recabar tus Datos Personales (en este acto, así como en actos
			pasados y/o futuros), es la persona moral denominada AREMA Producción y Servicios, SA de CV
			la cual trabaja bajo la marca registrada “AREMA Ticket®”.
		</p>
		<p>
			Para efectos de lo establecido en la fracción I del artículo 16 de la Ley, se señala como domicilio
			de AREMA Producciones y servicios, el ubicado en la Calle Jardín de San Jerónimo #109, Col Colinas de San Jerónimo, CP. 64640, Monterrey, Nuevo León, México.
		</p>
		<h1>3. Tratamiento de tus Datos Personales</h1>
		<p>
			De acuerdo con lo establecido en la Ley, se considera tratamiento de Datos Personales
			(“Tratamiento de Datos Personales”), la obtención, uso, divulgación o almacenamiento de Datos
			Personales, por cualquier medio. El uso abarca cualquier acción de acceso, manejo,
			aprovechamiento, transferencia o disposición de Datos Personales.
		</p>
		<p>
			El Tratamiento de Datos Personales por parte de AREMA Ticket® se limitará al cumplimiento de
			las Finalidades de Tratamiento previstas en el presente Aviso de Privacidad y se sujetará a lo
			siguiente:
		</p>
		<ol>
			<li>
				En caso que se pretenda tratar tus Datos Personales para un fin distinto a los fines del presente
				Aviso de Privacidad, AREMA Ticket® requerirá obtener tu consentimiento para tal fin, de nueva
				cuenta.
			</li>
			<li>
				Será el que resulte necesario, adecuado y relevante para los fines descritos en este Aviso de Privacidad.
			</li>
		</ol>
		<h1>4. Finalidades del tratamiento de Datos Personales</h1>
		<p>
			A través del presente Aviso de Privacidad, AREMA Ticket® declara que las finalidades del
			tratamiento de tus Datos Personales serán las siguientes (“Finalidades del Tratamiento”):
		</p>
		<ol type="a">
			<li>
				Fines comerciales, con el objeto de darte a conocer la información comercial de AREMA
				Ticket® y de los eventos de carácter público o privado que contratan nuestros servicios de sistema
				de boletaje, control de acceso, comercialización y distribución, entre la que se destaca la
				relacionada con promociones, concursos, información de nuevos eventos, nuevos productos,
				nuevos servicios, felicitaciones de cumpleaños, revista electrónica, cambios en nuestros productos,
				solicitar evaluación de la calidad en el servicio, realizar estudios internos sobre hábitos de
				consumo, funcionalidad de transacciones, y cualquier otro promocional o cuestión relacionada con
				los productos y/o servicios que forman parte del concepto comercialmente conocido como AREMA
				Ticket®.
			</li>
			<li>
				Venta en línea de boletos y transacciones, con el fin de realizar los cargos económicos a las
				tarjetas bancarias de los productos adquiridos por los clientes de AREMA Ticket®, ello derivado
				de compra de los productos o servicios de AREMA Ticket®.
			</li>
			<li>
				Entrega de productos y cumplimiento de obligaciones con nuestros clientes, en caso que
				adquieras alguna transacción electrónica, te hagas acreedor a algún premio derivado de las
				promociones, trivias o sorteos de AREMA Ticket®, o para cumplir con las obligaciones que en su
				caso tengamos o llegaremos a tener contigo, utilizaremos tus Datos Personales para contactarte o
				entregarte los productos.
			</li>
			<li>
				Reclutamiento y selección de personal, con el objetivo de que la persona que haya enviado
				su curriculum y/o información al correo especificado en el apartado de “únete” reciba la
				información oportuna sobre ofertas de empleo o vacantes que surjan en AREMA Ticket®.
				Nota: las operaciones de comercio electrónico conllevan el tratamiento de datos
				personales sensibles, tales como número de tarjeta de crédito o débito y otros
				datos relacionados.
			</li>
		</ol>
		<h1>5. Aceptación del tratamiento de Datos Personales</h1>
		<p>
			Para que AREMA Ticket® pueda llevar a cabo el Tratamiento de Datos Personales, nuestros
			clientes y/o titulares de Datos Personales deberán otorgarnos la aceptación expresa o tácita para
			dicho tratamiento, la cual se llevará por cualquiera de los siguientes medios:
		</p>
		<h2>5.1. Aceptación a través de medios electrónicos</h2>
		<ol type="a">
			<li>
				<b>Publicación en el Sitio de Internet de AREMA Ticket®</b> <br />
				<p>
					AREMA Ticket® publicará en su Sitio Web el presente Aviso de Privacidad, con el fin de que, una
					vez que te enteres de su contenido, otorgues o niegues tu consentimiento para que AREMA
					Ticket® pueda llevar a cabo el Tratamiento de tus Datos Personales.
					Esto también procederá en caso que, por cualquier motivo, AREMA Ticket® no cuente con tu
					dirección de correo electrónico o cuando tus Datos Personales sean erróneos o estén en desuso y
					no nos permitan localizarte. <br />
					Todo tratamiento de datos personales estará sujeto al consentimiento de su titular, salvo las
					excepciones previstas por la presente Ley. <br />
					El consentimiento será expreso cuando la voluntad se manifieste verbalmente, por escrito, por
					medios electrónicos, ópticos o por cualquier otra tecnología, o por signos inequívocos.
					Se entenderá que el titular consiente tácitamente el tratamiento de sus datos, cuando
					habiéndose puesto a su disposición el aviso de privacidad, no manifieste su oposición.
					es decir la aceptación explícita para que AREMA Ticket® posea los datos personales del cliente,
					para las finalidades de tratamiento de dichos datos mencionados en el Aviso de Privacidad
					publicado en www.arema.mx (Esto referido al artículo 8 de la presente ley).
					Los datos financieros o patrimoniales requerirán el consentimiento expreso de su titular, salvo las
					excepciones a que se refieren los artículos 10 y 37 de la presente Ley.
					El consentimiento podrá ser revocado en cualquier momento sin que se le atribuyan efectos
					retroactivos. Para revocar el consentimiento, el responsable deberá, en el aviso de privacidad,
					establecer los mecanismos y procedimientos para ello.
				</p>
			</li>
			<li>
				<b>Correo electrónico</b> <br />
				<p>
					Con base en la información que nos proporciones o nos hayas proporcionado, AREMA Ticket® te
					hará llegar el presente Aviso de Privacidad mediante correo electrónico, con el fin de que, una vez
					que te hayas enterado de su contenido, nos otorgues o niegues tu consentimiento para que
					AREMA Ticket® pueda llevar a cabo el Tratamiento de tus Datos Personales.
				</p>
			</li>
		</ol>

		<h2>5.2. Aceptación o rechazo del Aviso de Privacidad por medios electrónicos</h2>
		<p>
			La aceptación o el rechazo del Aviso de Privacidad de AREMA Ticket® por medios electrónicos
			será efectuada de la siguiente manera:
		</p>
		<ol type="a">
			<li>
				AREMA Ticket® te enviará a la dirección de correo electrónico que tenga registrada a tu
				nombre, la siguiente información:
				<ul>
					<li>La manifestación de que existe un Aviso de Privacidad de AREMA Ticket®, o las modificaciones o cambios que se realicen a dicho Aviso, en su caso.</li>
					<li>El contenido del Aviso de Privacidad y en su caso, las actualizaciones o adecuaciones que se realicen al mismo.</li>
					<li>Un vínculo electrónico mediante el cual puedas aceptar o no aceptar el Aviso o Privacidad, o los cambios que éste llegare a tener.</li>
				</ul>
			</li>
			<li>
				Una vez que hayas leído y estés enterado del contenido del Aviso de Privacidad, o bien, de los
				cambios que se le realicen, tendrás las siguientes opciones:
				<ul>
					<li>
						Aceptar. Mediante tu aceptación otorgas consentimiento y autorización para que AREMA
						Ticket® pueda utilizar tus Datos Personales de acuerdo a las Finalidades del Tratamiento
						establecidas en el Aviso de Privacidad.
					</li>
					<li>
						No aceptar. Cuando no aceptes el contenido del Aviso de Privacidad o sus modificaciones,
						niegas o cancelas tu consentimiento y autorización para que AREMA Ticket® utilice tus Datos
						Personales. <br />
						En este caso, AREMA Ticket® cancelará y dejará de usar tus Datos Personales para las
						Finalidades del Tratamiento establecidas en el Aviso de Privacidad.
					</li>
				</ul>
			</li>
		</ol>
		
		<h2>5.3. Aceptación de manera personal</h2>
		<p>
			Cuando los Datos Personales se obtengan personalmente del titular, AREMA Ticket® pondrá a tu
			disposición el presente Aviso de Privacidad en el momento en que sean recabados tus Datos
			Personales y de una forma clara y fehaciente, por medio de los formatos que AREMA Ticket®
			utiliza o llegare a utilizar para tal efecto. Además, a través de dichos formatos, AREMA Ticket®
			recabará la aceptación o negación del consentimiento del titular para someter dichos datos a las
			Finalidades del Tratamiento establecidas en el presente Aviso de Privacidad.
		</p>

		<h2>5.4. Aceptación tácita</h2>
		<p>
			Con base en lo establecido en el artículo 35 de la Ley, el contenido de este Aviso de
			Privacidad, así como cualquier modificación o adición al mismo te serán dadas a conocer por
			cualquiera de los medios establecidos en los apartados 5.1 y 5.2 que anteceden, y en caso que no
			manifiestes oposición al contenido y alcances de los mismos dentro de un periodo de 2 meses,
			contados a partir de la fecha en la que AREMA Ticket® te informe sobre el Aviso de Privacidad o
			sus modificaciones, se entenderá que otorgas tu consentimiento tácito para que AREMA Ticket®
			efectúe el tratamiento de tus Datos Personales con base en las Finalidades del Tratamiento
			establecidas en el Aviso de Privacidad.
		</p>
		
		<h2>5.5. Aceptación retroactiva</h2>
		<p>
			Para todos nuestros clientes que en fechas anteriores a la notificación o publicación del presente
			Aviso de Privacidad nos hayan proporcionado sus Datos Personales, ya sea mediante el llenado
			de formularios para transacciones, o por cualquier otra forma o medio, les será dado a conocer el
			Aviso de Privacidad conforme a lo establecido en los apartados 5.1 y 5.2 que anteceden, con el fin
			de que nos otorguen o nieguen su consentimiento (expreso o tácito) para que AREMA Ticket®
			pueda o no continuar llevando a cabo el Tratamiento de sus Datos Personales con base en las
			Finalidades del Tratamiento establecidas en el Aviso de Privacidad.
		</p>
		
		<h1>6.1. Acciones para prevenir el uso o divulgación no autorizada</h1>
		<h2>6.1. Acciones para prevenir el uso o divulgación no autorizada</h2>
		<p>
			Con el fin de garantizar la protección de tus Datos Personales y limitar el uso o divulgación no
			autorizada de los mismos, AREMA Ticket® continuamente realiza y realizará las siguientes
			acciones:
		</p>
		<ol type="a">
			<li>
				Confidencialidad de la información. AREMA Ticket® guardará confidencialidad respecto de
				tus Datos Personales recabados, misma que subsistirá aun después de finalizar sus relaciones
				comerciales o de otra naturaleza con el cliente o titular de dichos Datos Personales.
			</li>
			<li>
				Notificación de confidencialidad. En caso que, por algún motivo, AREMA Ticket® se vea en
				la necesidad de proporcionar tus Datos Personales a terceros (en los términos previstos en la Ley
				o en el presente Aviso de Privacidad), notificará a dichos terceros la obligación de cumplir con las
				disposiciones de la Ley y la confidencialidad de tus Datos Personales.
			</li>
			<li>
				Administración de bases de datos. Los Datos Personales son administrados y resguardados
				mediante el uso de bases de datos (“Base de Datos”), las cuales son administradas únicamente
				por las personas designadas por AREMA Ticket® para tal efecto, sin que se permita su uso,
				consulta, manejo o acceso a personas no autorizadas.
			</li>
			<li>
				Sistemas de cómputo e informáticos. Nuestras Bases de Datos están protegidas por firewalls
				y sistemas de cómputo y/o informáticos enfocados a prevenir y evitar el que personas ajenas a
				AREMA Ticket® o no autorizadas puedan acceder a tus Datos Personales.
			</li>
		</ol>
		
		<h2>6.2. Solicitudes para limitar el uso o divulgación</h2>
		<p>
			En caso que desees limitar el uso o divulgación de tus Datos Personales, en relación con una o
			varias de las Finalidades del Tratamiento de Datos Personales (como caso, por ejemplo, correos
			publicitarios, felicitaciones de cumpleaños, ofertas de empleo, entre otros), podrás enviar la
			solicitud respectiva conforme al procedimiento establecido en el apartado 7 del presente Aviso de
			Privacidad, o mediante el acceso a los vínculos que se cargan o llegaren a cargar en las páginas
			de internet de AREMA Ticket® o en la información promocional que te hacemos llegar.
		</p>
		
		<h1>7. Derechos de acceso, rectificación, cancelación u oposición</h1>
		<p>
			De acuerdo a lo establecido en la Ley, tú tienes derecho al acceso, rectificación, cancelación u
			oposición al Tratamiento de tus Datos Personales, para lo cual AREMA Ticket® te ofrece el
			siguiente procedimiento:
		</p>

		<h2>7.1. Departamento de Atención al Cliente</h2>
		<p>
			AREMA Ticket® cuenta con un departamento a cargo de la custodia de los Datos
			Personales, el cual dará trámite a tus solicitudes y fomentará la protección de los Datos Personales
			al interior de AREMA Ticket®. Para lo anterior, el Departamento de Atención al Cliente pone a tu
			disposición las siguientes formas de contacto y recepción de información y documentación
			relacionada:
			<ul>
				<li>Correo electrónico: hola@arema.mx</li>
				<li>Atención: Departamento de Atención al Cliente.</li>
			</ul>
		</p>

		<h2>7.2. Solicitud de acceso, rectificación, cancelación u oposición</h2>
		<p>
			En tu carácter de titular de Datos Personales, podrás presentar o enviar al Departamento de
			Atención al Cliente una solicitud para el acceso, rectificación, cancelación u oposición, respecto de
			tus Datos Personales. <br />
			Dicha solicitud deberá contener, por lo menos:
		</p>
		<ul>
			<li>
				Nombre completo y domicilio del titular de los Datos Personales, u otro medio para comunicarle la
				respuesta a su solicitud.
			</li>
			<li>
				Documentos que acrediten la identidad o la representación legal del titular de los Datos Personales.
			</li>
			<li>
				Descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno
				de los derechos antes mencionados.
			</li>
			<li>
				Cualquier otro elemento o documento que facilite la localización de los Datos Personales.
			</li>
			<li>
				Indicar de las modificaciones a realizarse y/o las limitaciones al uso de los Datos Personales,
				conforme a lo establecido en el apartado 6.2. del presente Aviso de Privacidad.
			</li>
			<li>
				Aportar la documentación que sustente su petición.
			</li>
		</ul>
		
		<h2>7.3. Resolución y comunicación</h2>
		<p>
			AREMA Ticket® comunicará al titular de los Datos Personales la determinación adoptada, en un
			plazo no mayor a 20 días hábiles contados desde la fecha en que se recibió la solicitud. Este plazo
			podrá ser ampliado por AREMA Ticket® en una sola ocasión por un periodo igual, siempre y
			cuando así lo justifiquen las circunstancias del caso.
		</p>
		<p>
			Con base en lo anterior, y de acuerdo con lo establecido en la Ley, AREMA Ticket® informará al
			titular de los Datos Personales el sentido y motivación de la resolución, por el mismo medio por el
			que se llevó a cabo la solicitud, y acompañará dicha resolución de las pruebas pertinentes, en su
			caso. Cuando la solicitud sea procedente, se hará efectiva por AREMA Ticket® dentro de los 15
			días hábiles siguientes a la comunicación de la resolución adoptada.
		</p>
		<p>
			El titular podrá presentar ante el Instituto Federal de Acceso a la Información (IFAI) una solicitud de
			protección de datos por la respuesta recibida o falta de respuesta de AREMA Ticket®. Dicha
			solicitud deberá presentarse por el titular dentro de los 15 días hábiles siguientes a la fecha en que
			se comunique la respuesta al titular por parte de AREMA Ticket®, y se sujetará a lo previsto en la
			Ley.
		</p>
		<p>
			En caso de solicitudes de acceso a Datos Personales, será necesario que el solicitante o su
			representante legal acrediten previamente su identidad.
			La obligación de acceso a la información se dará por cumplida cuando AREMA Ticket® ponga a
			disposición del titular los Datos Personales o mediante la expedición de copias simples o
			documentos electrónicos.
		</p>
		<p>
			En el supuesto en que una persona solicite a AREMA Ticket® el acceso a sus Datos Personales
			presumiendo que éste es el responsable y resultara que no lo es, bastará con que AREMA
			Ticket® así se lo indique al titular por cualquier medio, (de los establecidos en este apartado), para
			tener por desahogada la solicitud. La contestación a tu solicitud para acceso, rectificación,
			cancelación u oposición de los Datos Personales será gratuita. Deberás cubrir únicamente los
			gastos justificados de envío o el costo de reproducción en copias u otros formatos, los cuales, en
			su momento, te hará saber AREMA Ticket®.
		</p>
		<p>
			En caso que la misma persona reitere la solicitud de acceso, rectificación, cancelación u oposición
			de los Datos Personales en un periodo menor a 12 meses contado a partir de la fecha de la última
			solicitud, la contestación a tu solicitud podrá tener un costo adicional que señale AREMA Ticket®,
			los costos no serán mayores a tres días de Salario Mínimo General Vigente en el Distrito Federal, a
			menos que existan modificaciones sustanciales al aviso de privacidad que motiven nuevas
			consultas de acuerdo con lo establecido en artículo 35 de la Ley.
		</p>

		<h2>7.4. Negativa para acceder a Datos Personales</h2>
		<p>
			AREMA Ticket® podrá negar el acceso total o parcial a los Datos Personales o a la realización de
			la rectificación, cancelación u oposición al tratamiento de los mismos, en los siguientes supuestos:
		</p>
		<ul>
			<li>Cuando el solicitante no sea el titular o el representante legal no esté acreditado para ello.</li>
			<li>Cuando en la Base de Datos de AREMA Ticket® no se encuentren los Datos Personales del solicitante.</li>
			<li>Cuando se lesionen los derechos de un tercero.</li>
			<li>Cuando exista impedimento legal o resolución de una autoridad.</li>
			<li>Cuando la rectificación, cancelación u oposición haya sido previamente realizada, de manera que la solicitud carezca de materia.</li>
		</ul>
		
		<h2>7.5. Consecuencias de la cancelación</h2>
		<p>
			La cancelación de Datos Personales dará lugar a un periodo de bloqueo tras el cual AREMA
			Ticket® procederá a la supresión de los datos correspondientes. Una vez cancelados los Datos
			Personales correspondientes, AREMA Ticket® dará aviso a su titular. Hecho lo anterior, AREMA
			Ticket® podrá conservar los Datos Personales exclusivamente para efectos de las
			responsabilidades derivadas del tratamiento a que se refiere el Aviso de Privacidad.
			Cuando los Datos Personales se hubiesen transmitido a terceros antes de la rectificación o
			cancelación y sean tratados por dichos terceros, AREMA Ticket® deberá hacer de su
			conocimiento la solicitud presentada por el titular, para que procedan a efectuar tales
			rectificaciones o cancelaciones.
		</p>
		<p>
			AREMA Ticket® no estará obligado a cancelar tus Datos Personales cuando se trate de los
			supuestos establecidos en el artículo 26 de la Ley.
			Asimismo, cuando la información recabada en los Datos Personales deje de ser necesaria para el
			cumplimiento de las finalidades previstas en este Aviso de Privacidad y en las disposiciones
			jurídicas aplicables, tus Datos Personales serán cancelados de las Bases de Datos de AREMA
			Ticket®.
		</p>
		
		<h1>8. Cambios al aviso de privacidad</h1>
		<p>
			AREMA Ticket® se reserva el derecho de efectuar, en cualquier momento, modificaciones o
			actualizaciones al presente Aviso de Privacidad, para la atención de novedades legislativas o
			jurisprudenciales, políticas internas, nuevos requerimientos para la prestación u ofrecimiento de
			sus productos y servicios, o por prácticas comerciales.
		</p>
		<p>
			Por lo anterior, en caso que AREMA Ticket® llegare a modificar el contenido del presente Aviso de
			Privacidad, lo hará de tu conocimiento mediante alguna de las formas y medios establecidos en el
			apartado 5 del presente Aviso.
		</p>
		<p>El presente Aviso de Privacidad fue actualizado por última ocasión el 1 de Septiembre del 2011.</p>
		
		<h1>9. Transferencia de Datos Personales</h1>
		<p>
			En caso de que AREMA Ticket® llegare a transferir tus Datos Personales a alguno de sus
			proveedores con el fin de llevar a cabo las Finalidades del Tratamiento establecidas en el presente
			Aviso de Privacidad, lo hará previa celebración de convenios de confidencialidad y, siempre y
			cuando (i) el proveedor o persona a quien se le transmitan acepte someter el tratamiento de los
			Datos Personales al presente Aviso de Privacidad, y (ii) no se trate de alguno de los supuestos
			establecidos en el artículo 37 de la Ley.
		</p>
		<p>
			Si usted no manifiesta su oposición para que sus Datos Personales sean transferidos a terceros,
			se entenderá que ha otorgado a AREMA Ticket® su consentimiento para ello.
		</p>
		
		<h1>Consideraciones finales - Carácter informativo del presente documento</h1>
		<p>
			El contenido de este Aviso de Privacidad aparte de fungir como carácter informativo, conlleva la
			aceptación explícita para que AREMA Ticket® posea los datos personales del cliente, para las
			finalidades de tratamiento de dichos datos mencionados en el Aviso de Privacidad publicado en
			www.arema.mx ,por lo que en caso de que desees expresar tu aceptación o rechazo al
			contenido del mismo, te invitamos a ingresar a las secciones de recopilación de Datos Personales
			establecidas en las páginas de internet de AREMA Ticket®, en donde podrás emitir dicha
			aceptación o rechazo tanto al contenido del presente Aviso de Privacidad como a la posible
			transferencia de tus Datos Personales, o en su caso, envíes la solicitud correspondiente a la
			dirección de correo hola@arema.mx
		</p>
		
		<h1>ANEXO</h1>
		Para los efectos de esta Ley, se entenderá por:
		<ul>
			<li><b>Bases de datos</b>: El conjunto ordenado de datos personales referentes a una persona identificada o identificable.</li>
			<li><b>Consentimiento</b>: Manifestación de la voluntad del titular de los datos mediante la cual se efectúa el tratamiento de los mismos.</li>
			<li><b>Datos personales</b>: Cualquier información concerniente a una persona física identificada o identificable.</li>
			<li><b>Tercero</b>: La persona física o moral, nacional o extranjera, distinta del titular o del responsable de los datos</li>
		</ul>
	</div>
}

export default SitePrivacy;