import axios from 'axios';
import { APIResponse, Location } from '@arema/components/Classes';
import { DateData, OrderData } from './CheckoutClasses';
const ROOT_URL = process.env.REACT_APP_API_URL
const DEBUG = process.env.REACT_APP_DEBUG;

// var CACHE : { [x: string]: APIResponse<any> } = {};

async function post<T=any>(endpoint: string, data?: any, root_url: string=null) : Promise<APIResponse<T>>{
	var options = {
		headers: {
			'show-code': DEBUG ? 1 : 0,
			'Content-Type': 'application/json'
		}
	}
	if(data instanceof FormData){
		options.headers['Content-Type'] = 'multipart/form-data';
	}

	try{
		var ENDPOINT = `${ROOT_URL}/${root_url || 'checkout'}/${endpoint}`;
		if(DEBUG) console.log("POST "+ENDPOINT);
		var res = await axios.post(ENDPOINT, data, options);
		if(DEBUG) console.log(` => Response: ${(res.headers['content-length']/1024).toFixed(2)} KB`);
		return res.data as APIResponse<T>;
	}catch(err){
		if(DEBUG) console.error(err);
		return {
			error: true, 
			message: 'Hubo un error inesperado (API-LCL-1)',
			code: 'API-LCL-1',
		}
	}
};

// var cachePost = async <T>(endpoint: string, key: string, data: any) : Promise<APIResponse<T>>=>{
// 	if(CACHE[key]) return CACHE[key] as APIResponse<T>;
// 	var res = await post(endpoint, data);
// 	if(!res.error) CACHE[key] = res;
// 	return res;
// }

var getDate = (date_id: number, queue_token: string=null, access_token: string=null)=>{
	return post<DateData>('date', { date_id, queue_token, access_token });
}

var createOrder = (cart: { price_id: number, seat_id: number, quantity: number }[], delivery_id: number, captcha?: string, queue_token?: string)=>{
	return post('order/create', {
		cart, delivery_id, captcha, queue_token
	});
}

var getOrder = (token: string)=>{
	return post<OrderData>('order/get', {
		token
	})
}

var sendForm = (token: string, form: FormData)=>{
	form.append('token', token);
	return post('order/form/edit', form);
}

interface CheckoutData{
	first_name: string,
	last_name: string,
	email: string,
	zipcode: string,
	payment_method: number,
	card_token?: string,
	location?: Location
}
var completeOrder = (token: string, data: CheckoutData)=>{
	return post<{
		finished: boolean,
		paid: boolean,
		redirect?: string,
		payment_cost?: number, 
		tickets_url?: string,
		reference?: string,
		date_expiration?: number,
	}>('order/finish', {
		token,
		...data
	});
}

var getSeatmap = (date_id: number, section_id: number, queue_token?: string)=>{
	return post('seats', { date_id, section_id, queue_token });
}

var getAccessCode = async (event_id: number, code: string, captcha: string)=>{
	return post<{ token: string }>('events/access', { event_id, code, captcha }, 'public');
}

export default {
	completeOrder,
	getAccessCode,
	getDate,
	getOrder,
	getSeatmap,
	createOrder,
	sendForm,
}