import React, { useEffect, useState } from 'react';
import { Button } from 'react-frontier';
import { Modal } from 'semantic-ui-react';

interface ModalProps{
	open: boolean,
	onClose: ()=>void,
}

var SitePolicy = (props: ModalProps)=>{
	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>Políticas de AREMA Ticket</Modal.Header>
		<Modal.Content>
			<ul style={{ paddingLeft: 20 }}>
				<li>No existen cambios, reembolsos ni cancelaciones, en caso de ser cancelado el evento los cargos por servicio no serán reembolsados.</li>
				<li>En caso de que se exceda el límite de boletos, tu orden podrá ser cancelada.</li>
				<li>Cada boleto reservado a través de los Centros Telefónicos, Oficinas y la página de AREMA Ticket, está sujeto a un cargo por servicio adicional al precio del boleto. </li>
				<li>Antes de hacer tu reservación por favor revisa cuidadosamente el evento, sección, fecha y número de boletos solicitados, ya que una vez realizada ésta no hay cambios.</li>
				<li>Las reservaciones deberán efectuarse almenos con 2 días de anticipación, previas a la fecha del evento, tomando en cuenta las condiciones del tipo de entrega.</li>
				<li>
					ES IMPORTANTE que al momento de recoger tus boletos cuentes con:
					<ul style={{ paddingLeft: 20 }}>
						<li>Tu folio de compra (proporcionado al finalizar la reservación).</li>
						<li>Identificación oficial (UNICAMENTE credencial del INE, cartilla, pasaporte o cédula profesional).</li>
					</ul>
				</li>
				<li>En el caso de eventos cancelados, AREMA Ticket no se hace responsable por estos inconvenientes.</li>
				<li>Cuando recibas tus boletos guárdalos en un lugar seguro, lejos del calor, humedad o de la luz solar. AREMA Ticket no realiza reposición de boletos si éstos han sido perdidos, robados o dañados, quedando éstos bajo la responsabilidad del cliente.</li>
			</ul>
		</Modal.Content>
		<Modal.Actions>
			<Button text='Cerrar' onClick={props.onClose} />
		</Modal.Actions>
	</Modal>
}

export default SitePolicy;