import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface CountdownProps{
	unix: number,
	onFinish?: ()=>void,
	style?: React.CSSProperties,
	className?: string
	separate?: boolean,
	days?: boolean,
	labels?: {
		days: string,
		hours: string,
		minutes: string,
		seconds: string,
	}
}

var Countdown = (props: CountdownProps)=>{
	var [seconds, setSeconds] = useState(0);

	var getTime = ()=>{
		var now = Math.floor(new Date().getTime()/1000);
		setSeconds(Math.max(props.unix-now, 0));
		return props.unix-now;
	}

	var formatNumber = (num: number)=>{
		if(num===null) return null;
		return ('0'+num.toString()).slice(-2);
	}

	useEffect(()=>{
		var t = getTime();
		if(t<=0){
			if(props.onFinish) props.onFinish();
		}else{
			var interv = setInterval(()=>{
				var t = getTime();
				if(t<=0){
					clearInterval(interv);
					if(props.onFinish) props.onFinish()
				}
			}, 980)
		}
		return ()=>{
			if(interv) clearInterval(interv);
		}
	}, [props.unix])


	var hour = Math.floor((seconds/60)/60);
	var days = Math.floor(hour/24);
	if(props.days){
		hour = hour%24;
	}
	var min = Math.floor((seconds/60)%60);
	var sec = Math.floor((seconds%60));
	var time = `${(days>0 && props.days) ? (days + ':') : ''}${hour>0 ? (formatNumber(hour) + ':') : ''}${formatNumber(min)}:${formatNumber(sec)}`;
	if(props.separate){
		return <div className={props.className}>
			{!!props.days && (
				<div className={classNames('days', { active: days>0 })}>
					{days}
					{!!props.labels && !!props.labels.days && (
						<div className="label">{props.labels.days}</div>
					)}
				</div>
			)}
			<div className={classNames('hours', { active: days<=0 && hour>0 })}>
				{formatNumber(hour)}
				{!!props.labels && !!props.labels.hours && (
					<div className="label">{props.labels.hours}</div>
				)}
			</div>
			<div className={classNames('minutes', { active: days<=0 && hour<=0 && min })}>
				{formatNumber(min)}
				{!!props.labels && !!props.labels.minutes && (
					<div className="label">{props.labels.minutes}</div>
				)}
			</div>
			<div className={classNames('seconds', { active: days<=0 && hour<=0 && min<=0 })}>
				{formatNumber(sec)}
				{!!props.labels && !!props.labels.seconds && (
					<div className="label">{props.labels.seconds}</div>
				)}
			</div>
		</div>
	}else{
		return <div className={props.className} style={props.style}>{time}</div>
	}
}

export default Countdown;