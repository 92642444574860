import React, { useEffect, useState } from 'react';
import { Button, Groupper, Input, Message } from 'react-frontier';
import { CAPTCHA_ACTIVE, RECAPTCHA } from '../CheckoutConfig';
import { SetLoading } from '@arema/components/Classes';
import ReCAPTCHA from 'react-google-recaptcha';
import Validator from '@arema/components/Validator';
import API from '../CheckoutAPI';

interface AccessCodeProps{
	eventId: number,
	onToken: (token: string)=>void,
}

var AccessCodeForm = (props: AccessCodeProps)=>{
	var [accessCode, setAccessCode] = useState<string>(null);
	var [captcha, setCaptcha] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);

	var sendAccessCode = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator({ accessCode, captcha }, {
			accessCode: [{
				rule: 'minLength', params: [3], prompt: 'El código de acceso es inválido',
			}],
			captcha: [{
				rule: 'empty', prompt: 'Favor de completar el captcha', if: !!CAPTCHA_ACTIVE
			}]
		});
		setErrorPrompts(prompts);
		if(!valid) return;
	
		setLoading(true);
		API.getAccessCode(props.eventId, accessCode, captcha).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(res.data?.token){
				return props.onToken(res.data.token);
			}else{
				setErrorPrompts(['Hubo un error enviando el código de acceso (LCL-2)']);
			}
		}).catch(err=>{
			setErrorPrompts(['Hubo un error enviando el código de acceso (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}
	
	return <div className="ar code-form">
		<Groupper width={400} title={'Código de acceso'} actions={(
			<Button color='black' text='Continuar' onClick={sendAccessCode} />
		)}>
			<Message type='info' centered>
				Este evento requiere de un código de acceso para comprar. Favor de ingresarlo para continuar
			</Message>
			<Input label='Código de acceso' value={accessCode} onChange={setAccessCode} style={{ textAlign: 'center', marginTop: 15 }} inputStyle={{ fontSize: 16, textAlign: 'center' }} />
			{!!CAPTCHA_ACTIVE && (
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<ReCAPTCHA 
						hl='es-419'
						sitekey={RECAPTCHA}
						onChange={setCaptcha}
						onExpired={()=>setCaptcha(null)}
					/>
				</div>
			)}
			<Message list={errorPrompts} type='error' style={{ marginTop: 15 }} />
		</Groupper>
	</div>
}

export default AccessCodeForm;