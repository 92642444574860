import React, { useEffect, useState } from 'react';
import { Button, Header, Input, Message, Stat } from 'react-frontier';
import { addCommas } from '../../Util';

interface InstructionsProps{
	reference: string,
	amount: number,
	download_url?: string,
}
var InstructionsOxxo = (props: InstructionsProps)=>{
	return <div>
		<Header text='Pago en OXXO' />
		<Input label='Referencia' value={props.reference} inputStyle={{ textAlign: 'center', fontWeight: 'bold', fontSize: 20 }} style={{ maxWidth: 350, margin: 'auto', marginTop: 10 }} labelStyle={{ textAlign: 'center' }} />
		<Stat value={addCommas(props.amount)} label='Total por pagar' style={{ paddingBottom: 0 }} />
		<ol style={{ paddingLeft: 20 }}>
			<li>Acude a la tienda OXXO más cercana.</li>
			<li>Indica en caja que quieres realizar un pago de <strong>OXXOPay</strong>.</li>
			<li>Dicta al cajero el número de referencia superior para que tecleé directamete en la pantalla de venta.</li>
			<li>Realiza el pago correspondiente con dinero en efectivo.</li>
			<li>Al confirmar tu pago, el cajero te entregará un comprobante impreso. <strong>En el podrás verificar que se haya realizado correctamente.</strong> Conserva este comprobante de pago.</li>
		</ol>
		{/* {!!props.download_url && (
			<Button size='big' target='_blank' href={props.download_url} style={{ width: 280, margin: 'auto', display: 'block', marginTop: 10, marginBottom: 15 }} text='Descargar instrucciones' iconName='pdf file' />
		)} */}
		<Message type='orange'>
			Al completar estos pasos, <b>AREMA Ticket</b> te enviará un correo con tus boletos. El sistema puede tardar alrededor de 20-40 minutos en registrar tu pago.
		</Message>
	</div>
}

export default InstructionsOxxo;